import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <div className={"container"}>
      <div className={"content"}>
        <div className={"title"}>
          <h1>Politica de confidențialitate</h1>
        </div>
        <div>
          <p>
            Suntem foarte încântați că ați arătat interes pentru compania
            noastră. Protecția datelor are o prioritate deosebit de importantă
            pentru gestionarea Doctor Mihaluta SRL. Utilizarea paginilor de
            internet ale Doctor Mihaluta SRL este posibilă fără indicarea
            datelor personale; cu toate acestea, dacă o persoană vizată de
            protecția datelor cu caracter personal dorește să utilizeze servicii
            comerciale speciale prin intermediul site-ului nostru, prelucrarea
            datelor cu caracter personal ar putea deveni necesară. Dacă
            prelucrarea datelor cu caracter personal este necesară și nu există
            o bază legală pentru o astfel de prelucrare, noi putem obține în
            general consimțământul persoanei vizate.
          </p>

          <p>
            Prelucrarea datelor cu caracter personal, cum ar fi numele, adresa,
            adresa de e-mail sau numărul de telefon al unui subiect vizat de
            protecția datelor cu caracter personal, trebuie să fie întotdeauna
            în conformitate cu Regulamentul general privind protecția datelor
            (GDPR) și în conformitate cu reglementările privind protecția
            datelor specifice fiecărei țări aplicabile Doctor Mihaluta SRL. Prin
            intermediul acestei declarații de protecție a datelor, compania
            noastră dorește să informeze publicul larg cu privire la natura și
            scopul datelor cu caracter personal pe care le colectăm, folosim și
            procesăm. Mai mult, persoanele vizate sunt informate, prin
            intermediul acestei declarații privind protecția datelor, asupra
            drepturilor pe care le dețin.
          </p>

          <p>
            În calitate de operator al datelor cu caracter personal, Doctor
            Mihaluta SRL a implementat numeroase măsuri tehnice și
            organizatorice pentru a asigura cea mai completă protecție a datelor
            personale prelucrate prin intermediul acestui site web. Cu toate
            acestea, transmisiile prin intermediul Internetului pot, în
            principiu, să aibă lacune de securitate, astfel încât protecția
            absolută poate să nu fie garantată. Din acest motiv, fiecare
            persoană vizată are libertatea de a ne transfera date cu caracter
            personal prin mijloace alternative, de ex. prin telefon.
          </p>

          <h2>1. Definiții</h2>

          <p>
            Declarația privind protecția datelor a Doctor Mihaluta SRL se
            bazează pe termenii utilizați de legiuitorul european pentru
            adoptarea Regulamentului general privind protecția datelor (GDPR).
            Declarația noastră privind protecția datelor trebuie să fie ușor de
            citit și ușor de înțeles pentru publicul larg, precum și pentru
            clienții și partenerii noștri de afaceri. Pentru a asigura acest
            lucru, am dori să explicăm mai întâi terminologia utilizată.
          </p>

          <p>
            În această declarație privind protecția datelor, folosim, printre
            altele, următorii termeni:
          </p>

          <h3>a) Date cu caracter personal</h3>

          <p>
            Datele cu caracter personal înseamnă orice informație referitoare la
            o persoană fizică identificată sau identificabilă ("persoana
            vizată"). O persoană fizică identificabilă este una care poate fi
            identificată, direct sau indirect, în special prin referire la un
            identificator cum ar fi un nume, un număr de identificare, date
            despre locație, un identificator online sau unul sau mai mulți
            factori specifici: factor fizic, fiziologic, genetic, mental,
            economic, cultural sau social al acelei persoane fizice.
          </p>

          <h3>b) Persoana vizată</h3>

          <p>
            Persoana vizată este orice persoană fizică identificată sau
            identificabilă, ale cărei date cu caracter personal sunt prelucrate
            de către operatorul responsabil cu prelucrarea datelor.
          </p>

          <h3>c) Prelucrarea datelor cu caracter personal</h3>

          <p>
            Procesarea datelor cu caracter personal este orice operațiune sau
            set de operațiuni care se efectuează pe date personale sau pe seturi
            de date cu caracter personal, indiferent dacă sunt sau nu sunt
            obținute prin mijloace automate, precum colectarea, înregistrarea,
            organizarea, structurarea, stocarea, adaptarea sau modificarea,
            recuperarea, consultarea, divulgarea prin transmitere, diseminare
            sau punerea la dispoziție în alt mod, alinierea sau combinarea,
            restricționarea, ștergerea sau distrugerea.
          </p>

          <h3>d) Restricționarea procesării</h3>

          <p>
            Restricționarea procesării reprezintă marcarea datelor cu caracter
            personal stocate în scopul limitării procesării acestora în viitor.
          </p>

          <h3>e) Monitorizare a comportamentului</h3>

          <p>
            Profilare înseamnă orice formă de prelucrare automată a datelor cu
            caracter personal care constă în utilizarea datelor cu caracter
            personal pentru a evalua anumite aspecte personale referitoare la o
            persoană fizică, în special pentru a analiza sau prezice aspecte
            privind performanța persoanei fizice la locul de muncă, situația
            economică, sănătatea, preferințele personale , interese,
            fiabilitate, comportament, locație sau deplasări.
          </p>

          <h3>f)&nbsp;Pseudonimizarea</h3>

          <p>
            Pseudonimizarea este prelucrarea datelor cu caracter personal făcută
            astfel încât datele personale nu mai pot fi atribuite persoanei
            vizate fără utilizarea unor informații suplimentare, cu condiția ca
            aceste informații suplimentare să fie păstrate separat și să facă
            obiectul unor măsuri tehnice și organizatorice care să asigure că
            datele cu caracter personal nu sunt atribuite unei persoane fizice
            identificate sau identificabile.
          </p>

          <h3>g) Operator sau operator responsabil cu procesarea datelor</h3>

          <p>
            Operatorul sau operatorul responsabil de prelucrarea datelor este
            persoana fizică sau juridică, autoritatea publică, agenția sau alt
            organism care, singură sau împreună cu alții, determină scopurile și
            mijloacele de prelucrare a datelor cu caracter personal; în cazul în
            care scopurile și mijloacele unei asemenea prelucrări sunt stabilite
            de legislația Uniunii sau de statul membru, operatorul sau
            criteriile specifice de numire a acestuia pot fi prevăzute de
            legislația Uniunii sau de statul membru.
          </p>

          <h3>h) Persoană împuternicită de operator</h3>

          <p>
            Persoana împuternicită de operator este o persoană fizică sau
            juridică, o autoritate publică, o agenție sau un alt organism care
            prelucrează date cu caracter personal în numele operatorului.
          </p>

          <h3>i)&nbsp;Destinatar</h3>

          <p>
            Destinatarul este o persoană fizică sau juridică, o autoritate
            publică, o agenție sau un alt organism, căruia i se dezvăluie datele
            cu caracter personal, indiferent dacă este vorba despre un terț sau
            nu. Cu toate acestea, autoritățile publice care pot primi date cu
            caracter personal în cadrul unei anchete în conformitate cu
            legislația Uniunii sau a statelor membre nu sunt considerate
            destinatari; prelucrarea acestor date de către autoritățile publice
            respective trebuie să fie în conformitate cu normele aplicabile
            privind protecția datelor în conformitate cu scopurile procesării.
          </p>

          <h3>j)&nbsp;Terță parte</h3>

          <p>
            Terța parte este o persoană fizică sau juridică, o autoritate
            publică, o agenție sau un organism altul decât persoana vizată,
            operator, persoană împuternicită de operator și persoane care, sub
            directa autoritate a operatorului sau a persoanei împuternicite de
            operator, sunt autorizate să proceseze date cu caracter personal.
          </p>

          <h3>k) Consimțământ</h3>

          <p>
            Consimțământul persoanei vizate este orice indicare specifică,
            informată și lipsită de ambiguitate a dorințelor persoanei vizate
            prin care aceasta, printr-o declarație sau printr-o acțiune clară
            afirmativă, acceptă prelucrarea datelor cu caracter personal care o
            vizează.
          </p>

          <h2>2. Numele și Adresa Operatorului</h2>

          <p>
            Operatorul în sensul Regulamentului general privind protecția
            datelor (GDPR), alte altor legi privind protecția datelor aplicabile
            în statele membre ale Uniunii Europene și alte dispoziții
            referitoare la protecția datelor este:
          </p>

          <p>Doctor Mihaluta SRL</p>

          <p>Sat Slobozia Conachi, Comuna Slobozia Conachi, Nr. 121 A</p>

          <p>568 Galati</p>

          <p>Romania</p>

          <p>Telefon: 0755 341 659</p>

          <p>Website: https://www.mihaluta.ro</p>

          <h2>3. Cookies</h2>

          <p>
            Paginile de internet ale Doctor Mihaluta SRL utilizează cookies.
            Cookies sunt fișiere text care sunt stocate într-un sistem
            informatic prin intermediul unui browser de Internet.
          </p>

          <p>
            Multe site-uri de Internet și servere utilizează cookies. Multe
            cookies conțin un ID cookie. Un ID cookie este un identificator unic
            al cookie-ului. Este compus dintr-un șir de caractere prin care
            paginile și serverele de Internet pot fi atribuite browserului de
            Internet în care a fost stocat cookie-ul. Acest lucru permite
            site-urilor web și serverelor vizitate să diferențieze browserul
            individual al subiectului de alte browsere de Internet care conțin
            alte cookie-uri. Un anumit browser de Internet poate fi recunoscut
            și identificat utilizând unicul cookie ID.
          </p>

          <p>
            Prin utilizarea cookie-urilor, Doctor Mihaluta SRL poate oferi
            utilizatorilor acestui site mai multe servicii ușor de utilizat,
            care nu ar fi posibile fără setarea cookie-urilor.
          </p>

          <p>
            Prin intermediul unui cookie, informațiile și ofertele de pe site-ul
            nostru pot fi optimizate în funcție de utilizator. Cookie-urile ne
            permit, după cum am menționat anterior, să recunoaștem utilizatorii
            site-ului nostru web. Scopul acestei recunoașteri este de a ușura
            utilizarea site-ului nostru web. Utilizatorul site-ului web care
            utilizează cookies, de ex. nu trebuie să introducă date de acces de
            fiecare dată când site-ul este accesat, deoarece acesta este preluat
            de site-ul web, iar cookie-ul este astfel stocat în sistemul
            informatic al utilizatorului. Un alt exemplu este cookie-ul unui coș
            de cumpărături într-un magazin online. Magazinul online își
            amintește articolele pe care un client le-a plasat în coșul de
            cumpărături virtuale prin intermediul unui modul cookie.
          </p>

          <p>
            Persoana vizată poate, în orice moment, să împiedice setarea
            cookie-urilor prin intermediul site-ului nostru web prin intermediul
            unei setări corespunzătoare a browserului de internet utilizat și
            poate astfel să refuze definitiv setarea cookie-urilor. În plus,
            cookie-urile deja setate pot fi șterse în orice moment prin
            intermediul unui browser de Internet sau al altor programe software.
            Acest lucru este posibil în toate browserele de Internet populare.
            Dacă persoana vizată dezactivează setarea cookie-urilor în browserul
            de internet utilizat, nu toate funcțiile site-ului nostru pot fi
            utilizate în totalitate.
          </p>

          <h2>
            4. Colectarea datelor și informațiilor generale cu caracter personal
          </h2>

          <p>
            Site-ul Doctor Mihaluta SRL colectează o serie de date și informații
            generale atunci când un subiect sau un sistem automat utilizează
            site-ul. Aceste date și informații generale sunt stocate în
            fișierele jurnal server. Colectate pot fi : (1) tipurile de browser
            și versiunile utilizate, (2) sistemul de operare folosit de sistemul
            de acces, (3) site-ul web de la care ajunge un sistem de acces la
            site-ul nostru (așa-numitele referre) (5) data și ora accesului la
            site-ul internet, (6) adresa de protocol Internet (adresa IP), (7)
            furnizorul de servicii Internet al sistemului de acces și (8) orice
            alte date similare și informații care pot fi folosite în cazul
            atacurilor asupra sistemelor noastre de tehnologie a informației.
          </p>

          <p>
            Atunci când utilizați aceste date și informații generale, Doctor
            Mihaluta SRL nu trage concluzii cu privire la persoana vizată. Mai
            degrabă, aceste informații sunt necesare pentru a: (1) furniza
            corect conținutul site-ului nostru, (2) optimiza conținutul
            site-ului nostru, precum și publicitatea acestuia; (3) asigura
            viabilitatea pe termen lung a sistemelor noastre de tehnologie a
            informației , și (4) să furnizeze autorităților de aplicare a legii
            informațiile necesare pentru urmărirea penală în cazul unui atac
            cibernetic. Prin urmare, analizele Doctor Mihaluta SRL colectează
            anonim date și informații statistice, cu scopul de a spori
            securitatea și protecția datelor companiei noastre și de a asigura
            un nivel optim de protecție a datelor personale pe care le procesăm.
            Datele anonime ale fișierelor de tip jurnal sunt stocate separat de
            toate datele personale furnizate de persoana vizată de protecția
            datelor cu caracter personal.
          </p>

          <h2>5. Posibilitatea de contact prin intermediul site-ului</h2>

          <p>
            Site-ul Doctor Mihaluta SRL conține informații care permit un
            contact electronic rapid cu compania noastră, precum și o comunicare
            directă cu noi, care include și o adresă generală a așa-numitei
            poște electronice (adresa de e-mail). Dacă persoana vizată
            contactează operatorul prin e-mail sau printr-un formular de
            contact, datele personale transmise de persoana vizată sunt stocate
            automat. Astfel de date cu caracter personal transmise în mod
            voluntar de către persoana vizată către operatorul de date sunt
            stocate în scopul prelucrării sau contactării persoanei vizate. Nu
            există transfer de date cu caracter personal către terțe părți.
          </p>

          <h2>
            6. Ștergerea și blocarea de rutină a datelor cu caracter personal
          </h2>

          <p>
            Operatorul de date prelucrează și stochează datele cu caracter
            personal ale persoanei vizate numai pentru perioada necesară pentru
            atingerea scopului de stocare sau, în măsura în care acest lucru
            este acordat de legiuitorul european sau de alți legislatori în
            legile sau reglementările cărora li se aplică la operator.
          </p>

          <p>
            În cazul în care scopul depozitării nu este aplicabil sau dacă o
            perioadă de depozitare prevăzută de legiuitorul european sau de un
            alt legislator competent expiră , datele cu caracter personal sunt
            în mod obișnuit blocate sau șterse în conformitate cu cerințele
            legale.
          </p>

          <h2>7. Drepturile persoanei vizate</h2>

          <h3>a) Dreptul de confirmare</h3>

          <p>
            Fiecare persoană vizată are dreptul acordat de către legiuitorul
            european pentru a obține de la operator o confirmare cu privire la
            faptul că datele cu caracter personal care îl privesc sunt sau nu
            sunt procesate. Dacă persoana vizată dorește să se folosească de
            acest drept de confirmare, el sau ea poate, în orice moment, să
            contacteze orice angajat al operatorului.
          </p>

          <h3>b) Dreptul de acces</h3>

          <p>
            Fiecare persoană vizată are dreptul acordat de către legiuitorul
            european pentru a obține de la operator informații gratuite cu
            privire la datele sale personale stocate în orice moment și o copie
            a acestor informații. În plus, directivele și regulamentele europene
            acordă accesul persoanelor vizate la următoarele informații:
          </p>

          <p>
            În plus, persoana vizată are dreptul de a obține informații cu
            privire la transferarea datelor cu caracter personal către o țară
            terță sau către o organizație internațională. În acest caz, persoana
            vizată are dreptul de a fi informată cu privire la garanțiile
            corespunzătoare privind transferul.
          </p>

          <p>
            Dacă o persoana vizată dorește să se prevaleze de acest drept de
            acces, el sau ea poate, în orice moment, să contacteze orice angajat
            al operatorului.
          </p>

          <p>scopul prelucrării;</p>

          <p>categoriile de date cu caracter personal vizate;</p>

          <p>
            destinatarii sau categoriile de destinatari cărora le-au fost sau
            vor fi dezvăluite datele cu caracter personal, în special
            destinatari din țări terțe sau organizații internaționale;
          </p>

          <p>
            dacă este posibil, perioada prevăzută pentru care vor fi stocate
            datele cu caracter personal sau, dacă nu este posibil, criteriile
            utilizate pentru stabilirea acestei perioade;
          </p>

          <p>
            existența dreptului de a solicita rectificării sau ștergerii de date
            cu caracter personal de la operator sau de restricționare a
            prelucrării datelor cu caracter personal referitoare la persoana
            vizată sau de a se opune unei asemenea prelucrări;
          </p>

          <p>
            existența dreptului de a depune o plângere la o autoritate de
            supraveghere;
          </p>

          <p>
            în cazul în care datele cu caracter personal nu sunt colectate de la
            persoana vizată, orice informații disponibile cu privire la sursa
            acestora;
          </p>

          <p>
            existența unui proces automat de luare a deciziilor, inclusiv
            profilarea, menționată la articolul 22 alineatele (1) și (4) din
            GDPR și, cel puțin în acele cazuri, informații semnificative cu
            privire la logica implicată, precum și semnificația și consecințele
            preconizate ale unei astfel de prelucrari pentru persoana vizată.
          </p>

          <h3>c) Dreptul la rectificare</h3>

          <p>
            Fiecare persoană vizată are dreptul acordat de către legiuitorul
            european pentru a obține de la operator, fără întârzieri
            nejustificate, rectificarea datelor personale inexacte cu privire la
            acesta. Ținând cont de scopurile prelucrării, persoana vizată are
            dreptul de a completa datele personale incomplete, inclusiv prin
            furnizarea unei declarații suplimentare.
          </p>

          <p>
            Dacă o persoana vizată dorește să exercite acest drept la
            rectificare, el sau ea poate, în orice moment, să contacteze orice
            angajat al operatorului.
          </p>

          <h3>d) Dreptul la ștergerea datelor („dreptul de a fi uitat”)</h3>

          <p>
            Fiecare persoană vizată are dreptul acordat de către legiuitorul
            european pentru obținerea de la operator a ștergerii datelor cu
            caracter personal care îl privesc fără întârzieri nejustificate, iar
            operatorul are obligația de a șterge fără întârziere date cu
            caracter personal în cazul în care unul dintre motivele următoare se
            aplică, atâta timp cât prelucrarea nu este necesară:
          </p>

          <p>
            Dacă unul dintre motivele menționate mai sus se aplică și persoana
            vizată dorește să solicite ștergerea datelor cu caracter personal
            stocate de Doctor Mihaluta SRL, el sau ea poate oricând să
            contacteze orice angajat al operatorului. Un angajat al Doctor
            Mihaluta SRL trebuie să se asigure imediat că cererea de ștergere
            este respectată.
          </p>

          <p>
            În cazul în care operatorul a făcut publice datele cu caracter
            personal și este obligat, în conformitate cu articolul 17 alineatul
            (1), să ștearga datele cu caracter personal, operatorul, luând în
            considerare tehnologia disponibilă și costurile de implementare, ia
            măsuri rezonabile, inclusiv măsuri tehnice, de a informa alți
            operatori care prelucrează datele cu caracter personal că persoana
            vizată a solicitat ștergerea de către acești operatori a oricăror
            legături, copiere sau replicare a acestor date cu caracter personal,
            în măsura în care prelucrarea nu este necesară. Un angajat al Doctor
            Mihaluta SRL va organiza măsurile necesare în cazuri individuale.
          </p>

          <p>
            Datele cu caracter personal nu mai sunt necesare în ceea ce privește
            scopurile pentru care au fost colectate sau prelucrate în alt mod.
          </p>

          <p>
            Persoana vizată retrage consimțământul pe care se bazează
            prelucrarea în conformitate cu articolul 6 alineatul (1) litera (a)
            din GDPR sau articolul 9 alineatul (2) litera (a) din GDPR și unde
            nu există alt motiv juridic pentru prelucrare.
          </p>

          <p>
            Persoana vizată obiectează prelucrarea făcută în conformitate cu
            articolul 21 alineatul (1) din GDPR și nu există motive legale
            imperative de prelucrare sau persoana vizată se opune prelucrării în
            temeiul articolului 21 alineatul (2) din GDPR.
          </p>

          <p>Datele personale au fost procesate ilegal.</p>

          <p>
            Datele cu caracter personal trebuie să fie șterse pentru respectarea
            unei obligații legale în legislația Uniunii sau a statului membru la
            care este supus operatorul.
          </p>

          <p>
            Datele cu caracter personal au fost colectate în legătură cu oferta
            serviciilor societății informaționale menționate la articolul 8
            alineatul (1) din GDPR.
          </p>

          <h3>e) Dreptul la restricționarea prelucrării</h3>

          <p>
            Fiecare persoană vizată are dreptul acordat de către legiuitorul
            european pentru obținerea de la operator a restricționării
            prelucrării în cazul în care se aplică una dintre următoarele:
          </p>

          <p>
            Dacă una dintre condițiile menționate mai sus este îndeplinită și
            persoana vizată dorește să solicite restricția prelucrării datelor
            cu caracter personal stocate de Doctor Mihaluta SRL, aceasta poate
            oricând să contacteze orice angajat al operatorului. Angajatul
            Doctor Mihaluta SRL va aranja restricționarea procesării.
          </p>

          <p>
            Acuratețea datelor cu caracter personal este contestată de persoana
            vizată, pentru o perioadă care permite operatorului să verifice
            acuratețea datelor cu caracter personal.
          </p>

          <p>
            Prelucrarea este ilegală, iar persoana vizată se opune ștergerii
            datelor cu caracter personal și solicită în schimb limitarea
            utilizării acestora.
          </p>

          <p>
            Operatorul nu mai are nevoie de datele cu caracter personal în
            scopul prelucrării, dar acestea sunt solicitate de persoana vizată
            pentru stabilirea, exercitarea sau apărarea revendicărilor de natură
            juridică.
          </p>

          <p>
            Persoana vizată a formulat obiecții privind prelucrarea, în
            conformitate cu articolul 21 alineatul (1) din GDPR, în așteptarea
            verificării dacă motivele legitime ale operatorului sunt în
            contradicție cu cele ale persoanei vizate.
          </p>

          <h3>f) Dreptul la portabilitatea datelor</h3>

          <p>
            Fiecare persoană vizată are dreptul acordat de către legiuitorul
            european, de a primi datele cu caracter personal care i-au fost
            furnizate unui operator, într-un format structurat, utilizat în mod
            obișnuit și în format prelucrabil automat. Acesta are dreptul să
            transmită aceste date altui operator fără a fi împiedicat de către
            operatorul căruia i-au fost furnizate datele cu caracter personal,
            atât timp cât prelucrarea se bazează pe consimțământul prevăzut la
            articolul 6 alineatul (1) litera (a) din a GDPR sau a articolului 9
            alineatul (2) litera (a) din GDPR sau a unui contract în temeiul
            articolului 6 alineatul (1) litera (b) din GDPR, iar prelucrarea se
            face prin mijloace automate, atâta timp cât prelucrarea nu este
            necesară pentru îndeplinirea unei sarcini în interes public sau în
            exercitarea autorității publice conferite operatorului.
          </p>

          <p>
            În plus, în exercitarea dreptului său la portabilitatea datelor în
            conformitate cu articolul 20 alineatul (1) din GDPR, persoana vizată
            are dreptul să transmită date cu caracter personal direct de la un
            operator la altul, dacă acest lucru este fezabil din punct de vedere
            tehnic și atunci când acest lucru nu afectează negativ drepturile și
            libertățile celorlalți.
          </p>

          <p>
            în vederea exercitării dreptului la portabilitatea datelor, persoana
            vizată poate contacta în orice moment orice angajat al Doctor
            Mihaluta SRL.
          </p>

          <h3>g) Dreptul la opoziție</h3>

          <p>
            Fiecare persoană vizată are dreptul acordat de legiuitorul european,
            în orice moment, din motive legate de situația sa particulară, la
            prelucrarea datelor cu caracter personal care o privesc, care se
            bazează pe litera (e) sau (f) ) din articolul 6 alineatul (1) din
            GDPR. Acest lucru se aplică, de asemenea, profilării bazate pe
            aceste dispoziții.
          </p>

          <p>
            Doctor Mihaluta SRL nu va mai prelucra datele cu caracter personal
            în cazul în care obiecția nu este posibilă, cu excepția cazului în
            care putem demonstra motive legitime convingătoare pentru prelucrare
            care are prioritate față de interesele, drepturile și libertățile
            persoanei vizate sau pentru stabilirea, exercitarea sau apărarea
            revendicărilor legale .
          </p>

          <p>
            În cazul în care Doctor Mihaluta SRL prelucrează date cu caracter
            personal în scopuri direct de marketing , persoana vizată are
            dreptul de a formula obiecții în orice moment prelucrării datelor cu
            caracter personal care o privesc pentru o astfel de comercializare.
            Aceasta se aplică profilării în măsura în care este legată de un
            astfel de marketing direct. Dacă persoana vizată obiectează Doctor
            Mihaluta SRL la prelucrarea în scopuri direct de marketing , Doctor
            Mihaluta SRL nu va mai prelucra datele personale în aceste scopuri.
          </p>

          <p>
            În plus, persoana vizată are dreptul, din motive legate de situația
            sa particulară, de a se opune prelucrării datelor cu caracter
            personal care o privesc de către Doctor Mihaluta SRL în scopuri
            științifice sau istorice de cercetare sau în scopuri statistice în
            conformitate cu articolul 89 alineatul 1) din GDPR, cu excepția
            cazului în care prelucrarea este necesară pentru îndeplinirea unei
            sarcini îndeplinite din motive de interes public.
          </p>

          <p>
            Pentru a-și exercita dreptul de a obiecta, persoana vizată poate
            contacta orice angajat al Doctor Mihaluta SRL. În plus, persoana
            vizată este liberă în contextul utilizării serviciilor societății
            informaționale ,și fără a aduce atingere Directivei 2002/58 / CE, să
            utilizeze dreptul său de a formula obiecții prin mijloace automate
            prin utilizarea specificațiilor tehnice.
          </p>

          <h3>
            h) Procesul decizional individual automatizat, inclusiv crearea de
            profiluri
          </h3>

          <p>
            Fiecare persoană vizată are dreptul acordat de către legiuitorul
            european să nu facă obiectul unei decizii bazate exclusiv pe
            prelucrarea automatizată, inclusiv profilarea, care produce efecte
            juridice care o privesc sau o afectează în mod semnificativ, atât
            timp cât decizia (1) nu este necesară pentru încheierea sau
            executarea unui contract între persoana vizată și un operator de
            date sau (2) nu este autorizat de legislația Uniunii sau a statului
            membru la care este supusă operatorul și care prevede, de asemenea,
            adoptarea de măsuri adecvate pentru a proteja drepturile și
            libertățile persoanei vizate și interesele legitime sau (3) nu se
            bazează pe consimțământul explicit al subiectului vizat.
          </p>

          <p>
            În cazul în care decizia (1) este necesară pentru încheierea sau
            executarea unui contract între persoana vizată și un operator de
            date sau (2) se bazează pe acordul explicit al persoanei vizate,
            Doctor Mihaluta SRL va pune în aplicare măsuri adecvate pentru a
            proteja drepturile și libertățile și interesele legitime ale
            subiectului vizat, cel puțin dreptul de a obține o intervenție umană
            din partea operatorului, de a-și exprima punctul de vedere și de a
            contesta decizia.
          </p>

          <p>
            În cazul în care persoana vizată dorește să-și exercite drepturile
            privind luarea automată a deciziilor individuale, el sau ea poate,
            în orice moment, să contacteze orice angajat al Doctor Mihaluta SRL.
          </p>

          <h3>
            i) Dreptul de a retrage consimțământul privind protecția datelor
          </h3>

          <p>
            Fiecare persoană vizată are dreptul acordat de către legiuitorul
            european de a-și retrage consimțământul în privința prelucrării
            datelor sale personale în orice moment.
          </p>

          <p>
            În cazul în care persoana vizată dorește să își exercite dreptul de
            a retrage consimțământul, el sau ea poate, în orice moment, să
            contacteze orice angajat al Doctor Mihaluta SRL.
          </p>

          <h2>
            8. Protecția datelor referitoare la aplicarea și utilizarea Facebook
          </h2>

          <p>
            Pe acest site, operatorul are componente integrate ale companiei
            Facebook. Facebook este o rețea socială.
          </p>

          <p>
            O rețea socială este un loc pentru întâlniri sociale pe Internet, o
            comunitate online, care de obicei permite utilizatorilor să comunice
            și să interacționeze într-un spațiu virtual. O rețea socială poate
            servi drept platformă pentru schimbul de opinii și experiențe sau
            poate permite comunității online să furnizeze informații personale
            sau comerciale. Facebook permite utilizatorilor de rețele sociale să
            includă crearea de profiluri private, să încarce fotografii și să se
            conecteze prin intermediul cererilor de prietenie.
          </p>

          <p>
            Compania de operare este Facebook, Inc., 1 Hacker Way, Menlo Park,
            CA 94025, Statele Unite ale Americii. Dacă o persoană locuiește în
            afara Statelor Unite sau a Canadei, operatorul este Facebook Ireland
            Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irlanda.
          </p>

          <p>
            Cu fiecare accesare a uneia dintre paginile individuale ale acestui
            site, care este gestionat de către operatorul responsabil cu
            protecția datelor și în care a fost integrat un plug-in Facebook,
            browserul sistemului informatic al persoanei vizate va afișa în mod
            automat componenta Facebook. O prezentare generală a tuturor
            pluginurilor Facebook poate fi accesată la
            https://developers.facebook.com/docs/plugins/. În cursul acestei
            proceduri tehnice, Facebook este informat ce sub-site specific al
            site-ului nostru a fost vizitat de către persoana vizată.
          </p>

          <p>
            În cazul în care persoana vizată este conectată în același timp pe
            Facebook, Facebook detectează fiecare solicitare de acces a
            persoanei vizate - și pe întreaga durată de accesare a site-ului
            nostru - al cărui sub-site specific a fost vizitat de către persoana
            vizată. Aceste informații sunt colectate prin intermediul
            componentei Facebook și asociate cu contul Facebook al persoanei
            vizate. Dacă persoana vizată face clic pe unul dintre butoanele
            Facebook integrate în site-ul nostru, de ex. butonul "Caută" sau
            dacă persoana vizată transmite un comentariu, atunci Facebook
            asociază aceste informații cu contul personal de utilizator Facebook
            al persoanei vizate și stochează datele cu caracter personal.
          </p>

          <p>
            Facebook primește întotdeauna, prin componenta Facebook, informații
            despre o vizită făcută pe site-ul nostru de către persoana vizată,
            ori de câte ori persoana vizată este conectată în același timp pe
            Facebook în timpul accesării site-ului nostru. Acest lucru are loc
            indiferent dacă persoana vizată utilizează componenta Facebook sau
            nu. Dacă o astfel de transmitere a informațiilor către Facebook nu
            este de dorit pentru persoana vizată, atunci el sau ea ar putea
            împiedica acest lucru prin deconectarea de pe contul lor Facebook
            înainte de a accesa site-ul nostru.
          </p>

          <p>
            Ghidul privind protecția datelor publicat de Facebook, care este
            disponibil la adresa https://facebook.com/about/privacy/, oferă
            informații despre colectarea, prelucrarea și utilizarea datelor
            personale de către Facebook. În plus, se explică ce opțiuni de
            setare oferă Facebook pentru a proteja confidențialitatea persoanei
            vizate. În plus, sunt disponibile diferite opțiuni de configurare
            pentru a permite eliminarea transferului de date pe Facebook. Aceste
            aplicații pot fi folosite de persoana vizată pentru a elimina un
            transfer de date pe Facebook.
          </p>

          <h2>
            9. Dispoziții privind protecția datelor privind aplicarea și
            utilizarea serviciului Google Analytics (cu funcție de anonimizare)
          </h2>

          <p>
            Pe acest site, operatorul a integrat componenta Google Analytics (cu
            funcția de anonimizare). Google Analytics este un serviciu de
            analiză web. Analiza web este colectarea și analizarea datelor
            despre comportamentul vizitatorilor pe site-uri Web. Un serviciu de
            analiză web colectează, printre altele, date despre site-ul web
            accesat de o persoană (așa-numitul referrer), care subpagini au fost
            vizitate sau cât de des și pentru ce durată a fost vizualizată o
            subpagină. Analizele web sunt utilizate în principal pentru
            optimizarea unui site web și pentru a realiza o analiză
            cost-beneficiu a publicității pe internet.
          </p>

          <p>
            Operatorul componentei Google Analytics este Google Inc., 1600
            Amphitheatre Pkwy, Mountain View, CA 94043-1351, Statele Unite ale
            Americii.
          </p>

          <p>
            Pentru analiza web prin Google Analytics, operatorul folosește
            aplicația "_gat. _anonymizeIp". Prin intermediul acestei aplicații,
            adresa IP a conexiunii la Internet a persoanei vizate este abreviată
            de Google și anonimă atunci când accesează site-urile noastre
            dintr-un stat membru al Uniunii Europene sau dintr-un alt stat
            contractant al Acordului privind Spațiul Economic European.
          </p>

          <p>
            Scopul componentei Google Analytics este de a analiza traficul pe
            site-ul nostru. Google utilizează datele și informațiile colectate,
            printre altele, pentru a evalua utilizarea site-ului nostru și
            pentru a furniza rapoarte online care să arate activitățile pe
            site-urile noastre și să furnizeze alte servicii privind utilizarea
            site-ului nostru de internet de către noi.
          </p>

          <p>
            Google Analytics plasează un modul cookie în sistemul informatic al
            persoanei vizate. Definiția cookie-urilor este explicată mai sus. Cu
            setarea cookie-ului, Google are posibilitatea de a analiza
            utilizarea site-ului nostru. Cu fiecare accesare a uneia dintre
            paginile individuale ale acestui site Internet, care este gestionat
            de către operator și în care a fost integrată o componentă Google
            Analytics, browserul de internet din sistemul informatic al
            persoanei vizate va trimite automat date prin intermediul
            Componentei Google Analytics în scopul publicității online și al
            soluționării comisioanelor la Google. În cursul acestei proceduri
            tehnice, compania Google dobândește cunoștințe despre informațiile
            personale, cum ar fi adresa IP a persoanei vizate, care servește
            Google, printre altele, pentru a înțelege originea vizitatorilor și
            a clicurilor și pentru a crea ulterior decontări de comisioane.
          </p>

          <p>
            Cookie-ul este folosit pentru a stoca informații personale, cum ar
            fi timpul de acces, locația de la care a fost accesat și frecvența
            vizitelor site-ului nostru de către persoana vizată. Cu fiecare
            vizită pe site-ul nostru de internet, astfel de date personale,
            inclusiv adresa IP a accesului la Internet utilizată de persoana
            vizată, vor fi transmise la Google în Statele Unite ale Americii.
            Aceste date personale sunt stocate de Google în Statele Unite ale
            Americii. Google poate transmite aceste date personale colectate
            prin procedura tehnică unor terțe părți.
          </p>

          <p>
            Așa cum s-a menționat mai sus persoana vizată poate să împiedice
            setarea cookie-urilor prin intermediul site-ului nostru în orice
            moment prin intermediul unei ajustări corespunzătoare a browserului
            web utilizat și, prin urmare, să refuze permanent setarea
            cookie-urilor. O astfel de ajustare a browserului de internet
            utilizat ar împiedica Google Analytics să stabilească un modul
            cookie în sistemul informatic al persoanei vizate. În plus, modulele
            cookie deja utilizate de Google Analytics pot fi șterse în orice
            moment prin intermediul unui browser web sau al altor programe
            software.
          </p>

          <p>
            În plus, persoana vizată are posibilitatea de a contesta colectarea
            datelor generate de Google Analytics, care este legată de utilizarea
            acestui site web, precum și de prelucrarea acestor date de către
            Google și de posibilitatea de a exclude astfel de date . În acest
            scop, persoana vizată trebuie să descarce un add-on al browser-ului
            sub linkul https://tools.google.com/dlpage/gaoptout și să o
            instaleze. Acest add-on de browser îi transmite Google Analytics
            printr-un JavaScript, că orice date și informații despre vizitele
            paginilor de Internet ar putea să nu fie transmise către Google
            Analytics. Instalarea suplimentelor de browser este considerată o
            obiecție făcută de Google. Dacă sistemul informatic al persoanei
            vizate este ulterior șters, formatat sau nou instalat, atunci
            persoana vizată trebuie să reinstaleze programele de completare
            pentru a dezactiva Google Analytics. Dacă programul de adăugare a
            browserului a fost dezinstalat de către persoana vizată sau de orice
            altă persoană care poate fi atribuită sferei lor de competență sau
            este dezactivată, este posibilă executarea reinstalării sau
            reactivării add-on-urilor de browser.
          </p>

          <p>
            Informații suplimentare și dispozițiile aplicabile privind protecția
            datelor Google pot fi preluate în
            https://www.google.com/intl/ro/policies/privacy/ și la
            http://www.google.com/analytics/terms/us. html. Google Analytics
            este explicat în continuare la următorul link
            https://www.google.com/analytics/.
          </p>

          <h2>10. Temeiul juridic pentru prelucrare</h2>

          <p>
            Art. 6 (1) lit. un GDPR servește drept bază legală pentru
            operațiunile de procesare pentru care obținem consimțământul pentru
            un anumit scop de procesare. Dacă prelucrarea datelor cu caracter
            personal este necesară pentru executarea unui contract la care este
            parte persoana vizată, cum este cazul, de exemplu, atunci când
            operațiunile de prelucrare sunt necesare pentru furnizarea de bunuri
            sau pentru furnizarea oricărui alt serviciu, prelucrarea este pe
            baza articolului 6 alineatul (1) lit. b GDPR. Același lucru este
            valabil și pentru operațiunile de procesare care sunt necesare
            pentru realizarea măsurilor precontractuale, de exemplu în cazul
            anchetelor referitoare la produsele sau serviciile noastre. Compania
            noastră este supusă unei obligații legale prin care este necesară
            prelucrarea datelor cu caracter personal, cum ar fi îndeplinirea
            obligațiilor fiscale, prelucrarea se face pe baza art. 6 (1) lit. c
            GDPR. În cazuri rare, prelucrarea datelor cu caracter personal poate
            fi necesară pentru a proteja interesele vitale ale persoanei vizate
            sau ale unei alte persoane fizice. Acesta ar fi cazul, de exemplu,
            în cazul în care un vizitator a fost rănit în compania noastră și
            numele, vârsta, datele de asigurare de sănătate sau alte informații
            vitale ar trebui să fie transmise unui medic, spital sau unei alte
            părți terțe. Apoi, prelucrarea se va baza pe Art. 6 (1) lit. d GDPR.
            În cele din urmă, operațiunile de prelucrare s-ar putea baza pe
            articolul 6 alineatul (1) litera f GDPR. Acest temei juridic este
            utilizat pentru operațiunile de prelucrare care nu sunt acoperite de
            niciunul dintre motivele juridice menționate mai sus, dacă
            prelucrarea este necesară în scopul intereselor legitime urmărite de
            compania noastră sau de o terță parte, cu excepția cazului în care
            aceste interese sunt înlăturate de interese sau drepturile și
            libertățile fundamentale ale persoanei vizate care necesită
            protecție a datelor cu caracter personal. Astfel de operațiuni de
            prelucrare sunt în mod special permise deoarece au fost menționate
            în mod specific de legiuitorul european. El a considerat că un
            interes legitim ar putea fi asumat dacă persoana vizată este
            clientul operatorului (considerentul 47 din propoziția 2 GDPR).
          </p>

          <h2>
            11. Interesele legitime urmărite de operator sau de o terță parte
          </h2>

          <p>
            În cazul în care prelucrarea datelor cu caracter personal se bazează
            pe articolul 6 alineatul (1) litera f GDPR interesul nostru legitim
            este să ne desfășurăm afacerea în favoarea bunăstării tuturor
            angajaților și acționarilor noștri.
          </p>

          <h2>
            12. Perioada pentru care vor fi stocate datele cu caracter personal
          </h2>

          <p>
            Criteriile utilizate pentru stabilirea perioadei de stocare a
            datelor cu caracter personal sunt perioada de păstrare legală
            respectivă. După expirarea perioadei respective, datele
            corespunzătoare sunt șterse, atâta timp cât nu mai sunt necesare
            pentru îndeplinirea contractului sau inițierea unui contract.
          </p>

          <h2>
            13. Furnizarea de date cu caracter personal ca cerințe legale sau
            contractuale; Cerința necesară pentru a încheia un contract;
            Obligația persoanei vizate de a furniza datele cu caracter personal;
            consecințele posibile ale neîndeplinirii acestei obligații
          </h2>

          <p>
            Clarificăm că furnizarea de date cu caracter personal este cerută
            parțial de lege (de ex. Reglementări fiscale) sau poate rezulta și
            din dispoziții contractuale (de exemplu, informații privind
            partenerul contractual). Uneori poate fi necesar să se încheie un
            contract conform căruia persoana vizată ne furnizează date cu
            caracter personal, care trebuie prelucrate ulterior de noi. Persoana
            vizată este, de exemplu, obligată să ne furnizeze date cu caracter
            personal atunci când compania noastră semnează un contract cu el sau
            ea. Lipsa datelor cu caracter personal ar avea drept consecință
            faptul că nu a putut fi încheiat contractul cu persoana vizată.
            Înainte de furnizarea datelor cu caracter personal de către persoana
            vizată, persoana vizată trebuie să contacteze orice angajat.
            Angajatul clarifică persoanei vizate dacă furnizarea datelor cu
            caracter personal este cerută prin lege sau contract sau este
            necesară pentru încheierea contractului, dacă există o obligație de
            a furniza datele cu caracter personal și consecințele neautorizării
            datelor personale date.
          </p>

          <h2>14. Existența unui proces decizional automat</h2>

          <p>
            Ca o companie responsabilă, nu folosim un sistem automat de luare al
            deciziilor sau orice alt fel de profilare.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default PrivacyPage
